import axios from 'axios';

import config from 'app/config';

function checkHttpStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        let error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
}

function parseJSON(response) {
    return response.data;
}

let onReceive = data => {
    return data;
};

let get = token => {
    return axios
        .get(`${config.baseURI}/current-user`, {
            params: {},
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(onReceive);
};

export default {
    get: get
};
