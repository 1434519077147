import { createReducer } from 'lib/createReducer';

// Constants
export const FEATURE_FLAGS = {
    ORDERS: 'ORDERS',
    PRODUCT_RANGE: 'PRODUCT_RANGE',
    PRODUCT_PRICES: 'PRODUCT_PRICES',
    DRIVER_MANAGE: 'DRIVER_MANAGE',
    ASSIGN_ORDERS: 'ASSIGN_ORDERS',
    INTEGRATIONS: 'INTEGRATIONS'
};

// Actions
export const FEATURES_UPDATE = 'FEATURES_UPDATE';

// Utils

// Reducer
const initialState = {
    ORDERS: false,
    PRODUCT_RANGE: false,
    PRODUCT_PRICES: false,
    DRIVER_MANAGE: false,
    ASSIGN_ORDERS: false,
    INTEGRATIONS: true,
    initialised: false
};

export default createReducer(initialState, {
    [FEATURES_UPDATE]: (state, data) => {
        return {
            ...state,
            ORDERS: data.payload?.ORDERS ?? false,
            PRODUCT_RANGE: data.payload?.PRODUCT_RANGE ?? false,
            PRODUCT_PRICES: data.payload?.PRODUCT_PRICES ?? false,
            DRIVER_MANAGE: data.payload?.DRIVER_MANAGE ?? false,
            ASSIGN_ORDERS: data.payload?.ASSIGN_ORDERS ?? false,
            INTEGRATIONS: true,
            initialised: true
        };
    }
});

// Action Creators
const featuresUpdate = data => ({
    type: FEATURES_UPDATE,
    payload: data
});

// Actions
export const setFeatures = featureFlags => dispatch => dispatch(featuresUpdate(featureFlags));
