import { createReducer } from 'lib/createReducer';
import {
    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_FAILURE,
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER_REQUEST,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE
} from '../constants/Authentication';

const initialState = {
    token: null,
    user: null,
    didAttemptTokenVerification: false,
    didAttemptAuthentication: false,
    isAuthenticating: false,
    isVerifyingToken: false,
    isAuthenticated: false,
    tokenDeveloperMessage: null,
    loginDeveloperMessage: null,
    loginDisplayMessage: null
};

export default createReducer(initialState, {
    [VERIFY_TOKEN_REQUEST]: (state, payload) => {
        return Object.assign({}, state, {
            isVerifyingToken: true,
            tokenDeveloperMessage: null
        });
    },

    [VERIFY_TOKEN_FAILURE]: (state, data) => {
        return Object.assign({}, state, {
            didAttemptTokenVerification: true,
            isAuthenticated: false,
            isVerifyingToken: false,
            tokenDeveloperMessage: data.payload.tokenDeveloperMessage
        });
    },

    [VERIFY_TOKEN_SUCCESS]: (state, data) => {
        return Object.assign({}, state, {
            didAttemptTokenVerification: true,
            isAuthenticated: true,
            isVerifyingToken: false,
            tokenDeveloperMessage: 'You have been successfully authenticated (via token).',
            token: data.payload.token,
            user: data.payload.user
        });
    },

    [LOGIN_USER_REQUEST]: (state, payload) => {
        return Object.assign({}, state, {
            isAuthenticating: true,
            loginDisplayMessage: null,
            token: null,
            user: null
        });
    },

    [LOGIN_USER_FAILURE]: (state, data) => {
        return Object.assign({}, state, {
            isAuthenticating: false,
            didAttemptAuthentication: true,
            loginDisplayMessage: data.payload.loginDisplayMessage,
            token: null,
            user: null
        });
    },

    [LOGIN_USER_SUCCESS]: (state, data) => {
        return Object.assign({}, state, {
            isAuthenticating: false,
            didAttemptTokenVerification: true,
            isAuthenticated: true,
            didAttemptAuthentication: true,
            loginDisplayMessage: 'The user has logged in',
            token: data.payload.token,
            user: data.payload.user
        });
    },

    [LOGOUT_USER_REQUEST]: (state, data) => {
        return Object.assign({}, state);
    },

    [LOGOUT_USER_SUCCESS]: (state, data) => {
        return Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: false,
            didAttemptTokenVerification: true,
            token: null,
            user: null,
            loginDisplayMessage: null,
            tokenDeveloperMessage: 'You have been successfully logged out.'
        });
    },

    [LOGOUT_USER_FAILURE]: (state, data) => {
        return Object.assign({}, state, {
            tokenDeveloperMessage: 'There was a problem logging you out.'
        });
    }
});
