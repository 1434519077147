import { createReducer } from 'lib/createReducer';

// Actions
export const SETTINGS_SHOW_NAV = 'SETTINGS_SHOW_NAV';

// Reducer
const initialState = {
    showNav: true
};

export default createReducer(initialState, {
    [SETTINGS_SHOW_NAV]: (state, data) => {
        return {
            ...state,
            showNav: data.payload.showNav
        };
    }
});

// Action Creators
const settingsSetShowNav = data => ({
    type: SETTINGS_SHOW_NAV,
    payload: data
});

// Actions
export const setShowNav = showNav => dispatch => dispatch(settingsSetShowNav({ showNav }));
