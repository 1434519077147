import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { navActive } from 'app/util/nav';
import styles from './NavSidebar.module.scss';

const NavSidebar = ({ path }) => {
    const features = useSelector(state => state.features ?? {});
    const defaultProductsLink = `/products/${features.PRODUCT_RANGE ? 'range' : 'prices'}`;

    return (
        <section className={styles.sidebar}>
            <div className={styles.brand}>
                <img src="/assets/img/tipple-brand.svg" alt="Tipple Partner Portal" />
            </div>
            <section className={styles.nav}>
                <div className={styles.list}>
                    {features.ORDERS && <Link to="/orders/packing" className={classNames(styles.item, navActive(path, 'orders') && styles.active)}>
                        <img src={`/assets/img/icons/nav-orders${navActive(path, 'orders') ? '-active' : ''}.svg`} alt="Orders" />
                        <span>Orders</span>
                    </Link>}
                    <Link to={defaultProductsLink} className={classNames(styles.item, navActive(path, 'products') && styles.active)}>
                        <img src={`/assets/img/icons/nav-products${navActive(path, 'products') ? '-active' : ''}.svg`} alt="Products" />
                        <span>Products</span>
                    </Link>
                    {features.DRIVER_MANAGE && <Link to="/drivers" className={classNames(styles.item, navActive(path, 'drivers') && styles.active)}>
                        <img src={`/assets/img/icons/nav-drivers${navActive(path, 'drivers') ? '-active' : ''}.svg`} alt="Drivers" />
                        <span>Drivers</span>
                    </Link>}
                </div>
                <Link to="/auth/logout" className={classNames(styles.item, navActive(path, 'account') && styles.active, styles.end)}>
                    <img src={`/assets/img/icons/nav-account.svg`} alt="Logout" />
                    <span>Logout</span>
                </Link>
            </section>
        </section>
    );
};

export default NavSidebar;