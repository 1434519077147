/* eslint-disable */

import Cookies from 'js-cookie';
import * as uuidv4 from 'uuid/v4';
import config from 'app/config';
import { getStore } from '../../store';

export const AnalyticsData = {
    data: {},
    cart: null,
    user: null,
    page: null,
    error: null
};

export const AnalyticsEvents = {
    user_login_success: 'user_login_success', // app/resources/action/Login.js
    user_login_failed: 'user_login_failed',

    user_logout: 'user_logout', // app/resources/action/Logout.js
    user_is_guest: 'user_is_guest', // app/App.js
    user_identify: 'user_identify', // app/App.js

    order_clicked: 'order_clicked',
    order_accepted: 'order_accepted',
    order_packed_clicked: 'order_packed_clicked',
    order_packed_success: 'order_packed_success',

    sound_on: 'sound_on',
    sound_off: 'sound_off',
    sound_played: 'sound_played',

    analytics_exception: 'analytics_exception'
};

export const MapUrlToPageData = function(url) {
    let urlBits = url.split('/');
    if (urlBits[0] === '') {
        urlBits.shift();
    }
    if (urlBits.length === 0 || urlBits[0] === '') {
        return { name: 'Dashboard' };
    }
    const urlData = { name: 'Not Set' };
    switch (urlBits[0]) {
        case 'products':
            if (urlBits.length === 2 && urlBits[1] == 'range') {
                urlData.name = 'Range Categories';
            } else if (urlBits.length === 3 && urlBits[1] == 'range') {
                urlData.name = 'Range Products in Category';
            } else if (urlBits.length === 2 && urlBits[1] == 'stock') {
                urlData.name = 'Stock Categories';
            } else if (urlBits.length === 4 && urlBits[1] == 'stock' && urlBits[3] == 'view') {
                urlData.name = 'Stock Products All';
            } else if (urlBits.length === 4 && urlBits[1] == 'stock' && urlBits[3] != 'view') {
                urlData.name = 'Stock Products in Category';
            } else if (urlBits.length === 2 && urlBits[1] == 'prices') {
                urlData.name = 'Price Manager List';
            }
            break;
        case 'auth':
            if (urlBits.length === 2 && urlBits[1] == 'logout') {
                urlData.name = 'Logout';
            } else if (urlBits.length === 2 && urlBits[1] == 'login') {
                urlData.name = 'Login';
            }
        case 'orders':
            if (urlBits.length === 2 && urlBits[1] == 'packing') {
                urlData.name = 'Order Dashboard';
            } else if (urlBits.length === 2 && urlBits[1] == 'history') {
                urlData.name = 'Order History';
            }
            break;
        case 'dashboard':
            urlData.name = 'Dashboard';
            break;
        case 'notfound':
            urlData.name = '404';
            break;
    }
    return urlData;
};

export class Analytics {
    _analytics = [];
    /**
     * Creates an instance of Analytics.
     * @param {Segment} segment
     */
    constructor() {
        var Segment = require('./segment').default;
        const s = new Segment();
        this._analytics.push(s);
    }

    trigger(eventName, data) {
        const session_id = Cookies.get(config.userIdentifierCookie);
        let eventData = Object.assign({}, data);
        const store = getStore();
        const reduxStore = store.getState();
        const globalData = {};

        let selectedStore = null;

        if (reduxStore.filters?.selectedStoreId) {
            globalData.storeId = reduxStore.filters.selectedStoreId;

            if (reduxStore.STORES?.items?.length) {
                selectedStore = reduxStore.STORES.items.find(s => s.id === reduxStore.filters.selectedStoreId);
                if (selectedStore) {
                    globalData.storeName = selectedStore.name;
                    globalData.storeFeatures = selectedStore.features;
                }
                // store subs enabled
            }
        }

        if (reduxStore?.auth?.user?.userId) {
            globalData.userId = reduxStore.auth.user.userId;
        }

        const analyticsData = {
            session_id,
            web_message_id: uuidv4(),
            ...globalData
        };

        // We need to trigger this to all the different analytics components
        // but before we push the data, the data needs to sorted for that specific analytics platform
        try {
            // never crash because of analytics
            this._analytics.map(e => e.trigger(eventName, eventData, analyticsData));
            return true;
        } catch (e) {
            try {
                // maybe segment still works - send a message in a bottle
                this._analytics.map(a => a.trigger(AnalyticsEvents.analytics_exception, { error: e }));
            } catch (e2) {
                // but seriously, dont crash because of analytics
            }

            if (global.newrelic) {
                global.newrelic.noticeError(e);
            }
        }
        return false;
    }
}

export function enableAnalytics(config) {
    const segmentConfig = Object.assign({}, config);
    const data = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
  analytics.load("${segmentConfig.segmentKey}");
  }}();`;

    //return data;
    window.eval(data);
}
