import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as logoutAC from 'app/actions/Logout';

class LogoutPage extends React.Component {
    componentDidMount() {
        console.info('logout');
        this.props.actions.logoutUser(this.props.auth, this.props.history);
    }

    render() {
        return (
            <div>
                <span id="authentication-container" />
            </div>
        );
    }
}

LogoutPage.contextTypes = {};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(logoutAC, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogoutPage);
