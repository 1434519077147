import axios from 'axios';

import config from 'app/config';
import { standardHeaders } from 'lib/api/rest';

import { createReducer } from 'lib/createReducer';

// Actions
export const CLEAR_GEOCODED_ADDRESS = 'CLEAR_GEOCODED_ADDRESS';
export const SET_INPUT_ADDRESS = 'SET_INPUT_ADDRESS';

export const CONFIRM_GEOCODED_ADDRESS_SUCCESS = 'CONFIRM_GEOCODED_ADDRESS_SUCCESS';
export const CONFIRM_GEOCODED_ADDRESS_CANCEL = 'CONFIRM_GEOCODED_ADDRESS_CANCEL';
export const CONFIRM_GEOCODED_ADDRESS_REQUEST = 'CONFIRM_GEOCODED_ADDRESS_REQUEST';

export const GEOCODE_ADDRESS_REQUEST = 'GEOCODE_ADDRESS_REQUEST';
export const GEOCODE_ADDRESS_SUCCESS = 'GEOCODE_ADDRESS_SUCCESS';
export const GEOCODE_ADDRESS_FAILURE = 'GEOCODE_ADDRESS_FAILURE';

export const DISPLAY_ADDRESS_SELECT = 'DISPLAY_ADDRESS_SELECT';
export const HIDE_ADDRESS_SELECT = 'HIDE_ADDRESS_SELECT';

// Reducer
const initialState = {
    inputAddress: null,
    geocodedAddress: null,
    token: null,
    currentUser: null,
    isAuthenticated: false,
    isGeocodingAddress: false,
    geocodeErrorMessage: '',
    didAttemptGeocodeAddress: false,
    displayAddressConfirmation: false,
    displayAddressSelect: false
};

export default createReducer(initialState, {
    [DISPLAY_ADDRESS_SELECT]: (state, data) => {
        return Object.assign({}, state, {
            displayAddressSelect: true
        });
    },

    [HIDE_ADDRESS_SELECT]: (state, data) => {
        return Object.assign({}, state, {
            displayAddressSelect: false
        });
    },

    [CLEAR_GEOCODED_ADDRESS]: (state, data) => {
        return Object.assign({}, state, {
            inputAddress: null,
            geocodedAddress: null
        });
    },

    [SET_INPUT_ADDRESS]: (state, data) => {
        return Object.assign({}, state, {
            inputAddress: data.payload.inputAddress
        });
    },

    [GEOCODE_ADDRESS_REQUEST]: (state, data) => {
        return Object.assign({}, state, {
            geocodedAddress: null,
            geocodeErrorMessage: '',
            isGeocodingAddress: true
        });
    },

    [GEOCODE_ADDRESS_FAILURE]: (state, data) => {
        return Object.assign({}, state, {
            geocodedAddress: null,
            geocodeErrorMessage: data.payload.error,
            isGeocodingAddress: false
        });
    },

    [GEOCODE_ADDRESS_SUCCESS]: (state, data) => {
        return Object.assign({}, state, {
            geocodedAddress: data.payload.geocodedAddress,
            isGeocodingAddress: false,
            didAttemptGeocodeAddress: true
        });
    },

    [CONFIRM_GEOCODED_ADDRESS_SUCCESS]: (state, data) => {
        return Object.assign({}, state, {
            displayAddressConfirmation: false,
            displayAddressSelect: false
        });
    },

    [CONFIRM_GEOCODED_ADDRESS_REQUEST]: (state, data) => {
        return Object.assign({}, state, {
            displayAddressConfirmation: true
        });
    },

    [CONFIRM_GEOCODED_ADDRESS_CANCEL]: (state, data) => {
        return Object.assign({}, state, {
            displayAddressConfirmation: false
        });
    }
});

// Action Creators
export function displayAddressSelect() {
    return function(dispatch) {
        dispatch({
            type: DISPLAY_ADDRESS_SELECT
        });
    };
}

export function hideAddressSelect() {
    return function(dispatch) {
        dispatch({
            type: HIDE_ADDRESS_SELECT
        });
    };
}

export function clearGeocodedAddress() {
    return function(dispatch) {
        dispatch({
            type: CLEAR_GEOCODED_ADDRESS
        });
    };
}

export function confirmAddressRequest() {
    return function(dispatch) {
        dispatch({
            type: CONFIRM_GEOCODED_ADDRESS_REQUEST,
            payload: {}
        });
    };
}

export function confirmAddressCancel() {
    return function(dispatch) {
        dispatch({
            type: CONFIRM_GEOCODED_ADDRESS_CANCEL
        });
    };
}

export function confirmAddress(geocodedAddress, auth, history, fromGeocodeAddress = false) {
    return function(dispatch) {
        let date = new Date();
        date.setHours(date.getHours() + 24 * 30);

        if (geocodedAddress.storeId !== -1) {
            // dispatch(setCartAddress(geocodedAddress, auth, history, fromGeocodeAddress))

            dispatch({
                type: CONFIRM_GEOCODED_ADDRESS_SUCCESS,
                payload: {}
            });
        } else {
            dispatch({
                type: CONFIRM_GEOCODED_ADDRESS_CANCEL,
                payload: {}
            });

            // if (geocodedAddress.zones && geocodeAddress.zones.length > 0) {
            //     dispatch({
            //         type: DISPLAY_ADDRESS_ALTERNATIVE_STORE,
            //         payload: {
            //             geocodedAddress: geocodedAddress
            //         }
            //     });
            // } else {
            //     dispatch({
            //         type: DISPLAY_ADDRESS_UNSERVICEABLE,
            //         payload: {
            //             geocodedAddress: geocodedAddress
            //         }
            //     });
            // }

            // dispatch({
            //     type: STORE_UNVAILABLE,
            //     payload: {
            //         geocodedAddress: geocodedAddress
            //     }
            // });
        }
    };
}

export function setGeocodedAddress(geocodedAddress) {
    return function(dispatch) {
        dispatch({
            type: SET_INPUT_ADDRESS,
            payload: {
                inputAddress: geocodedAddress.formattedAddress
            }
        });

        dispatch({
            type: GEOCODE_ADDRESS_SUCCESS,
            payload: {
                geocodedAddress: geocodedAddress
            }
        });
    };
}

export function geocodeAddress(addressToGeocode, auth) {
    return function(dispatch) {
        dispatch({
            type: SET_INPUT_ADDRESS,
            payload: {
                inputAddress: addressToGeocode
            }
        });

        dispatch({
            type: GEOCODE_ADDRESS_REQUEST
        });

        axios
            .get(config.baseURI + '/geocode?address=' + addressToGeocode, {
                headers: standardHeaders(auth)
            })
            .then(resp => {
                if (resp.status === 200) {
                    if (
                        resp.error === undefined &&
                        resp.data.storeId !== -1 &&
                        resp.data.addressLine1 !== '' &&
                        resp.data.country === 'AU'
                    ) {
                        dispatch({
                            type: GEOCODE_ADDRESS_SUCCESS,
                            payload: {
                                geocodedAddress: resp.data
                            }
                        });

                        dispatch(confirmAddressRequest());
                        return;
                    } else if (resp.data.addressLine1 === '') {
                        dispatch({
                            type: GEOCODE_ADDRESS_FAILURE,
                            payload: {
                                error: 'This address is not valid'
                            }
                        });
                        return;
                    } else if (resp.data.country !== 'AU') {
                        dispatch({
                            type: GEOCODE_ADDRESS_FAILURE,
                            payload: {
                                error: 'This address is not valid'
                            }
                        });
                        return;
                    }

                    // if (resp.data.zones && resp.data.zones.length > 0) {
                    //     dispatch({
                    //         type: DISPLAY_ADDRESS_ALTERNATIVE_STORE,
                    //         payload: {
                    //             geocodedAddress: resp.data
                    //         }
                    //     });
                    // } else {
                    //     dispatch({
                    //         type: DISPLAY_ADDRESS_UNSERVICEABLE,
                    //         payload: {
                    //             geocodedAddress: resp.data
                    //         }
                    //     });
                    // }

                    // dispatch({
                    //     type: STORE_UNVAILABLE,
                    //     payload: {
                    //         geocodedAddress: resp.data
                    //     }
                    // });

                    return;
                } else {
                    console.llog('GA FAIL', resp);
                    dispatch({
                        type: GEOCODE_ADDRESS_FAILURE,
                        payload: {
                            error: resp.error
                        }
                    });
                }
            })
            .catch(error => {
                // If there is an API error ie. 400 Bad Request, error will be an object rather than a nice
                // error string (which is the response given by other failure scenarios), so override it.
                const errorResponse = typeof error !== 'object' ? error : 'This address is not valid';

                dispatch({
                    type: GEOCODE_ADDRESS_FAILURE,
                    payload: {
                        error: errorResponse
                    }
                });
            });
    };
}
