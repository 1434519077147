import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createStore from './store';

import { enableNewRelic } from './lib/trackError';
import { enableAnalytics } from './lib/analytics/analytics'
import config from './app/config';
import App from './app/App';

if (config.enableAnalytics !== false) {
    enableNewRelic({
        licenseKey: config.nrLicenseKey,
        applicationID: config.nrApplicationID,
        releaseName: config.nrReleaseName,
        releaseId: config.nrReleaseId
    });
    enableAnalytics({ segmentKey: config.segmentKey });
}

// Create a store and get back itself and its history object
const { store } = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

const root = document.getElementById('app-root');
render(Application, root);
