import { AnalyticsEvents, MapUrlToPageData } from './analytics';
import { Object } from 'core-js';

export default class Segment {
    analyticsLoaded = false;

    defaultOptions = {
        app: {
            name: process?.env?.REACT_APP_NAME,
            version: process?.env?.REACT_APP_VERSION
        }
    };

    defaultParameters = {};

    log(...args) {
        if (window.trackAnalytics) {
        }
    }

    trigger(eventName, eventData, analyticsData) {
        this.defaultParameters = { ...this.defaultParameters, ...analyticsData };

        if (!Object.hasOwnProperty.call(global, 'analytics')) {
            this.log(
                'SEGMENT TRACK NOT ENABLED: ' + eventName,
                { ...eventData, ...analyticsData },
                this.defaultParameters
            );
            return false;
        }
        this.log('SEGMENT TRIGGERING: ' + eventName, eventData, this.defaultParameters);
        switch (eventName) {
            case AnalyticsEvents.analytics_exception:
                this._track('Analytics Exception', { ...eventData });
                break;
            case AnalyticsEvents.user_logout:
                this._track('User Logout', {}, {}, () => {
                    this._reset();
                });
                break;
            case AnalyticsEvents.user_is_guest:
                this._identify(null);
                break;
            case AnalyticsEvents.user_login_success:
                this._track('User Login', {});
                break;
            case AnalyticsEvents.user_login_failed:
                this._track('User Login Failed', {});
                break;
            case AnalyticsEvents.user_identify:
                this._identify(eventData.user);
                break;
            case AnalyticsEvents.order_clicked:
                this._track('Order Clicked', this._formatOrderClicked(eventData));
                break;
            case AnalyticsEvents.order_accepted:
                this._track('Order Accepted', this._formatOrderAccepted(eventData));
                break;
            case AnalyticsEvents.order_packed_clicked:
                this._track('Order Pack Clicked', this._formatOrderPacked(eventData));
                break;
            case AnalyticsEvents.order_packed_success:
                this._track('Order Pack Accepted', this._formatOrderPackedSuccess(eventData));
                break;
            case AnalyticsEvents.sound_on:
                this._track('Sound On', {});
                break;
            case AnalyticsEvents.sound_off:
                this._track('Sound Off', {});
                break;
            case AnalyticsEvents.sound_played:
                this._track('Sound Played', {});
                break;
            case AnalyticsEvents.app_pageview:
                const pageData =
                    eventData && eventData.page && eventData.page.path
                        ? MapUrlToPageData(eventData.page.path)
                        : { name: 'Not Set' };
                let pageProperties = eventData ? eventData.data : {};

                if (!pageData.ignorePage) {
                    this._page(pageData.name, pageData.category, pageProperties);
                }
                break;
            default:
                this._track('Unhandled', { eventName: eventName, ...eventData, ...analyticsData });
                break;
        }
        return true;
    }

    _reset() {
        window.analytics.reset();
    }

    _formatOrderPacked(data) {
        return {
            order_id: data.orderId || null,
            order_number: data.orderNumber || null,
            order_subbed: data.orderSubbed || null,
            order_type: data.orderType || null,
            sound_volume: data.soundVolume || data.soundVolume === 0 ? data.soundVolume : null
        };
    }

    _formatOrderPackedSuccess(data) {
        return {
            ...this._formatOrderPacked(data),
            danger: data.danger || null
        };
    }

    _formatOrderClicked(data) {
        let ret = this._formatOrder(data);

        ret.from = data.from || null;
        ret.sound_volume = data.soundVolume || data.soundVolume === 0 ? data.soundVolume : null;

        return ret;
    }

    _formatOrderAccepted(data) {
        let ret = this._formatOrder(data);

        ret.time_to_accept = data.time_to_accept || null;
        ret.sound_volume = data.soundVolume || data.soundVolume === 0 ? data.soundVolume : null;

        return ret;
    }

    _formatOrder(data) {
        return {
            created: data.created || null,
            danger: data.danger || false,
            gift: data.gift || false,
            order_id: data.order_id || null,
            order_number: data.orderNumber || null,
            order_subbed: data.order_subbed || false,
            order_type: data.order_type || null,
            pack_time: data.pack_time || null,
            packing_status: data.packing_status || null,
            products: data.products || null,
            run_courier_id: data.run_courier_id || null,
            run_courier_name: data.run_courier_name || null,
            run_id: data.run_id || null,
            show_time: data.show_time || null,
            status: data.status || null,
            total: data.total || null
        };
    }

    _identify(user) {
        let traits = {
            logged_in: false,
            type: 'GUEST',
            ...this.defaultParameters
        };
        let userId = null;
        if (user) {
            userId = user.userId;
            traits.userId = user.userId;
            traits.storeName = user.storeName;
            traits.storeId = user.storeId;
            traits.subsEnabled = user.subsEnabled;
            traits.name = user.userName;
            traits.phone = user.mobile ? user.mobile.toString() : '';
            traits.createdAt = user.createdDate;
            traits.type = user.type ? user.type.toString() : 'PARTNER';
            traits.logged_in = true;
        }
        window.analytics.identify(userId, traits, this.defaultOptions);
    }

    _track(event, properties = {}, options = {}, callback = () => {}) {
        window.analytics.track(
            event,
            { ...this.defaultParameters, ...properties },
            { ...this.defaultOptions, ...options },
            callback
        );
    }

    _page(name, category, properties = {}, options = {}, callback = () => {}) {
        if (category != null) {
            window.analytics.page(
                category,
                name,
                { ...this.defaultParameters, ...properties },
                { ...this.defaultOptions, ...options },
                callback
            );
        } else {
            window.analytics.page(
                name,
                { ...this.defaultParameters, ...properties },
                { ...this.defaultOptions, ...options },
                callback
            );
        }
    }

    _alias(user, callback) {
        window.analytics.alias(user.id, undefined, this.defaultOptions, callback);
    }
}
