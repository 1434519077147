export default function reducers(resourceName, args) {
    return {
        [resourceName + '_UPDATE_REQUEST']: (state, payload) => {
            return Object.assign({}, state, {
                isRequestingUpdate: true,
                updatingId: payload?.updatingId ?? null,
                hasUpdated: false,
                errors: null
            });
        },

        [resourceName + '_UPDATE_FAILURE']: (state, payload) => {
            const dataErrors = payload.errors;
            dataErrors[0].data = payload.data;

            return Object.assign({}, state, {
                isRequestingUpdate: false,
                updatingId: null,
                item: null,
                hasUpdated: false,
                errors: dataErrors
            });
        },

        [resourceName + '_UPDATE_SUCCESS']: (state, payload) => {
            return Object.assign({}, state, {
                isRequestingUpdate: false,
                updatingId: null,
                item: payload.data,
                hasUpdated: true,
                errors: null
            });
        },

        [resourceName + '_UPDATE_COMPLETE']: (state, payload) => {
            return Object.assign({}, state, {
                isRequestingUpdate: false,
                updatingId: null,
                hasUpdated: false
            });
        }
    };
}
