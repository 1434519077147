import { createReducer } from 'lib/createReducer';

// Actions
export const FILTER_SET_STORE = 'FILTER_SET_STORE';

// Utils
export const getInTransit = filterValue => (filterValue === 'TRANSIT' ? true : filterValue === 'STORE' ? false : null);

// Reducer
const initialState = {
    selectedStoreId: null
};

export default createReducer(initialState, {
    [FILTER_SET_STORE]: (state, data) => {
        return {
            ...state,
            selectedStoreId: data.payload.storeId
        };
    }
});

// Action Creators
const filterSetStore = data => ({
    type: FILTER_SET_STORE,
    payload: data
});

// Actions
export const setStore = storeId => dispatch => dispatch(filterSetStore({ storeId }));
