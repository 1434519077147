import { createReducer } from 'lib/createReducer';
import { SEARCH_PARAMS_SUCCESS } from '../constants/SearchParams';

const initialState = {
    hasDecoded: false
};

export default createReducer(initialState, {
    [SEARCH_PARAMS_SUCCESS]: (state, data) => {
        return Object.assign(
            {
                hasDecoded: true
            },
            data.payload
        );
    }
});
