export default {
    cookieName: 'tipple-partner-token',
    authenticationURI: 'https://partner.tipple.com.au/api/partner',
    baseURI: 'https://partner.tipple.com.au/api/partner',
    deviceIdentifierCookie: 'tipple-device-identifier',
    googleMapsKey: 'AIzaSyC_FPclql_PIolUFekK192OcGPfDFC0pXg',
    googleApiKey: 'AIzaSyC_FPclql_PIolUFekK192OcGPfDFC0pXg',
    s3ImageBase: "https://content.tipple.com.au",
    imageBase: "https://content.tipple.com.au/tipple",
    nrLicenseKey: "5849d0b811",
    nrApplicationID: "402892034",
    segmentKey: 'LhAaFAQ3SWOtJEZDUA2QLpEzjIfarNJd',
    nrReleaseName: '76f4cf5981780f775283d47eb5c01c26d3cdadcc',
    nrReleaseId: '76f4cf5981780f775283d47eb5c01c26d3cdadcc',
    enableAnalytics: true,
    mapboxKey: 'pk.eyJ1IjoidGlwcGxlIiwiYSI6ImNrMG9rMWJ1YTBia2kzbHBrbXA4MnViNjUifQ.CHYrnxxi4oxOiRA7JW10WQ',
}