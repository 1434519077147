import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

const DashboardSection = Loadable({
    loader: () => import(/* webpackChunkName: "dashboard-section" */ './sections/dashboard/Routes'),
    loading: () => null,
    modules: ['dashboard-section'],
    webpack: () => [require.resolveWeak('./sections/dashboard/Routes')] // Prevents white flash
});

const ProductsSection = Loadable({
    loader: () => import(/* webpackChunkName: "products-section" */ './sections/products/Routes'),
    loading: () => null,
    modules: ['products-section'],
    webpack: () => [require.resolveWeak('./sections/products/Routes')] // Prevents white flash
});

const AppProductsSection = Loadable({
    loader: () => import(/* webpackChunkName: "app-products-section" */ './sections/products/RoutesApp'),
    loading: () => null,
    modules: ['app-products-section'],
    webpack: () => [require.resolveWeak('./sections/products/RoutesApp')] // Prevents white flash
});

const OrdersSection = Loadable({
    loader: () => import(/* webpackChunkName: "orders-section" */ './sections/orders/Routes'),
    loading: () => null,
    modules: ['orders-section'],
    webpack: () => [require.resolveWeak('./sections/orders/Routes')] // Prevents white flash
});

const DriversSection = Loadable({
    loader: () => import(/* webpackChunkName: "orders-section" */ './sections/drivers/Routes'),
    loading: () => null,
    modules: ['drivers-section'],
    webpack: () => [require.resolveWeak('./sections/drivers/Routes')] // Prevents white flash
});

const AppDriversSection = Loadable({
    loader: () => import(/* webpackChunkName: "app-products-section" */ './sections/drivers/RoutesApp'),
    loading: () => null,
    modules: ['app-products-section'],
    webpack: () => [require.resolveWeak('./sections/drivers/RoutesApp')] // Prevents white flash
});

export default () => (
    <Switch>
        <Route
            key="logout"
            path={`/auth/logout`}
            component={require('app/sections/authentication/pages/Logout').default}
        />

        <Route path="/orders" component={OrdersSection} />
        <Route path="/products" component={ProductsSection} />
        <Route path="/drivers" component={DriversSection} />

        {/* Need a /app prefix for any routes requiring the nav hidden */}
        <Route path="/app/drivers" component={(props) => <AppDriversSection {...props} showBackLink={true} />} />
        <Route path="/app" component={(props) => <AppProductsSection {...props} showBackLink={true} />} />

        <Route path="/" component={DashboardSection} />

        {/* Always redirect to dashboard on issue */}
        <Redirect to="/" />
    </Switch>
);
