export default function updateActionCreators(resourceName, actionTypes, api) {
    let actionCreators = {
        update: function(id, data, auth, queryParams, options = {}) {
            return function(dispatch) {
                dispatch(actionCreators.request(data?.updatingId ?? id ?? null));

                return api
                    .update(id, data, auth, queryParams)
                    .then(
                        response =>
                            dispatch(
                                response.errors
                                    ? actionCreators.failure(response.errors, response.data)
                                    : actionCreators.success(response, options?.successCallback ?? null)
                            ),
                        exception => dispatch(actionCreators.failure(exception))
                    )
                    .then(
                        response => dispatch(actionCreators.complete()),
                        exception => dispatch(actionCreators.complete())
                    );
            };
        },

        request: function(id) {
            let name = resourceName + '_UPDATE_REQUEST';

            return {
                type: actionTypes[name],
                updatingId: id
            };
        },

        success: function(data, callback) {
            let name = resourceName + '_UPDATE_SUCCESS';

            if (callback) {
                callback(data);
            }

            return {
                data: data,
                type: actionTypes[name]
            };
        },

        complete: function() {
            let name = resourceName + '_UPDATE_COMPLETE';

            return {
                data: {},
                type: actionTypes[name]
            };
        },

        failure: function(errors, data) {
            let name = resourceName + '_UPDATE_FAILURE';
            return {
                errors: errors,
                data: data ? data : null,
                type: actionTypes[name]
            };
        }
    };

    return actionCreators;
}
