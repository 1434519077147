import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavSidebar from 'app/components/navigationLayout/NavSidebar';

import styles from './NavigationLayout.module.scss';

const NavigationLayout = ({ children }) => {
    const history = useHistory();
    const path = history?.location?.pathname ?? '';
    const settingsShowNav = useSelector(state => state.settings?.showNav ?? true);
    const featuresInitialised = useSelector(state => state.features?.initialised ?? false);

    const showNavBar = settingsShowNav === false ? false : path.indexOf('/app') !== 0;

    return (
        <div className={styles.layout}>
            {showNavBar && <NavSidebar path={path} />}
            <section className={styles.main}>
                {featuresInitialised && children}
            </section>
        </div>
    );
};

export default NavigationLayout;