export default function createActionCreators(resourceName, actionTypes, api) {
    let actionCreators = {
        create: function(data, auth, queryParams) {
            return function(dispatch) {
                dispatch(actionCreators.createRequest(queryParams));

                return api
                    .create(data, auth, queryParams)
                    .then(
                        response =>
                            dispatch(
                                response.errors
                                    ? actionCreators.createFailure(response.errors, response.data)
                                    : actionCreators.createSuccess(response)
                            ),
                        exception => dispatch(actionCreators.createFailure(exception))
                    )
                    .then(
                        response => dispatch(actionCreators.createComplete()),
                        exception => dispatch(actionCreators.createComplete())
                    );
            };
        },

        createRequest: function(queryParams) {
            let name = resourceName + '_CREATE_REQUEST';

            return {
                type: actionTypes[name],
                meta: {
                    queryParams
                }
            };
        },

        createSuccess: function(data, queryParams) {
            let name = resourceName + '_CREATE_SUCCESS';

            return {
                data: data,
                type: actionTypes[name],
                meta: {
                    queryParams
                }
            };
        },

        createComplete: function(queryParams) {
            let name = resourceName + '_CREATE_COMPLETE';

            return {
                data: {},
                type: actionTypes[name],
                meta: {
                    queryParams
                }
            };
        },

        createFailure: function(errors, additional) {
            let name = resourceName + '_CREATE_FAILURE';

            return {
                errors: errors,
                additional: additional,
                type: actionTypes[name]
            };
        }
    };

    return actionCreators;
}
