// The basics
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from 'app/config';

import Routes from './Routes';
import { withRouter } from 'react-router';

import { Analytics, AnalyticsEvents } from '../lib/analytics/analytics';

import * as verifyTokenAC from './actions/VerifyToken';
import * as searchParamsAC from './actions/SearchParams';
import { setStore } from 'app/modules/Filters';
import { setFeatures } from 'app/modules/Features';
import AuthCheck from './components/AuthCheck';
import Loadable from 'react-loadable';
import NavigationLayout from 'app/components/NavigationLayout';

import ErrorBoundary from 'app/components/ErrorBoundary';
import Cookies from 'js-cookie';
import { Switch, Route, Redirect } from 'react-router-dom';

import registerStore from 'app/resources/store';

import './App.scss';
import '../scss/tipple-ranger.scss';

const storeRedux = registerStore('STORES', ['LIST']);

global.tippleAnalytics = new Analytics();
global.LC_API = global.LC_API || {};
global.LC_API.on_after_load = function() {
    global._LC_LOADED = true;
};

const Authentication = Loadable({
    loader: () => import(/* webpackChunkName: "authentication" */ './sections/authentication/Routes'),
    loading: () => null,
    modules: ['authentication'],
    webpack: () => [require.resolveWeak('./sections/authentication/Routes')] // Prevents white flash
});

class App extends React.Component {

    getOrderSummary() {
        // Don't get a new order summary if we are currently updating content
        if (this.props.runIsUpdating) {
            return;
        }
    }

    componentDidMount() {

        this.loadGooglePlaces()

        if (this.props.auth.isAuthenticated) {
            // GET stores list
            this.getStoresList();
        }

        this.props.vtActions.verifyToken(Cookies.get(config.cookieName));
        this.props.spActions.decodeSearchParams(this.props);
        this.onRouteChanged(this.props.location.pathname);
    }

    componentWillUnmount() {
        clearInterval(this.orderSummaryTimer);
        this.orderSummaryTimer = null;
    }

    onRouteChanged(pathname) {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                global.tippleAnalytics.trigger(AnalyticsEvents.app_pageview, { 'page': { 'path': pathname, }, 'data': { 'appUrl': pathname } });
            }, 200);
        }
    }

    loadGooglePlaces = () => {
        let googleApiKey = config.googleApiKey;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=Function.prototype`;
        script.async = true;
        document.body.appendChild(script);
    }

    registerLiveChatUser(user) {
        if(!global._LC_LOADED) {
            setTimeout(() => {
                this.registerLiveChatUser(user);
            }, 200);
        } else {
            if (user) {
                global.LC_API.set_visitor_name(user.storeName);
                global.tippleAnalytics.trigger(AnalyticsEvents.user_identify, { user: user });
            } else {
                global.LC_API.set_visitor_name(null);
                global.tippleAnalytics.trigger(AnalyticsEvents.user_is_guest, {});
            }

        }
    }

    componentDidUpdate(prevProps) {

        if (!prevProps.auth.isAuthenticated && this.props.auth.isAuthenticated) {
            if (this.props.userStoreId) {
                this.props.setUserStore( this.props.userStoreId );
            }
        }
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
            this.onRouteChanged(this.props.location.pathname);
        }

        if (this.props.location.search !== prevProps.location.search) {
            this.props.spActions.decodeSearchParams(this.props);
        }


        // console.log('AUTH',prevProps.auth,this.props.auth)
        // Check user verification has finished
        if ((!prevProps.auth.didAttemptTokenVerification && this.props.auth.didAttemptTokenVerification) || (!prevProps.auth.user && this.props.auth.user)) {
            // Check if the user is logged in

            this.registerLiveChatUser(this.props.auth.user);

        
            // GET stores list
            this.getStoresList();
        }

        if (this.props.auth.isAuthenticated && this.props.searchParams.hasDecoded && this.props.searchParams.nextPath) {
            this.props.history.push({ pathname: this.props.searchParams.nextPath });
        }

        if (prevProps.storesLoading && !this.props.storesLoading && this.props.storesLoaded) {
            const selectedStoreId = this.props.userStoreId ? this.props.userStoreId : this.props.stores[0]?.id;

            const selectedStore = this.props.stores.find(s => s.id === selectedStoreId);
            const storeFeatures = {};
    
            if (selectedStore?.features?.length > 0) {
                selectedStore.features.forEach(feature => {
                    storeFeatures[feature] = true;
                });
            }

            this.props.setFeatures(storeFeatures);
        }
    }

    getStoresList = () => {
        this.props.storeActions.list(this.props.auth);
    };

    render() {
        const props = this.props;
        if (!props.auth.isAuthenticated && !props.auth.didAttemptTokenVerification) {
            return <AuthCheck />
        }

        // TODO: This is adding /dashboard to the default redirect, but we need to fix why it's not going to /dashboard after a login with / as the default path
        if (!props.auth.isAuthenticated) {
            return <div>
                <Switch>
                    <Route path="/auth" component={Authentication} />
                    <Redirect from="/forgot-password/:email/:token" to="/auth/forgot-password/:email/:token" />
                    <Redirect to={`/auth/login?nextPath=${props.location.pathname === '/' ? '/dashboard' : props.location.pathname}`} />
                </Switch>
            </div>
        }

        return (
            <div id="app" className="app-container">
                <ErrorBoundary>
                    <NavigationLayout>
                        <Routes />
                    </NavigationLayout>
                </ErrorBoundary>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    searchParams: state.searchParams,
    userStoreId: state.auth?.user?.storeId ?? null,
    stores: state.STORES?.items,
    storesLoading: state.STORES?.isRequesting ?? false,
    storesLoaded: state.STORES?.hasRequested ?? false,
    featuresInitialised: state.features?.initialised ?? false
});

const mapDispatchToProps = dispatch => ({
    vtActions: bindActionCreators(verifyTokenAC, dispatch),
    spActions: bindActionCreators(searchParamsAC, dispatch),
    setUserStore: bindActionCreators(setStore, dispatch),
    storeActions: bindActionCreators(storeRedux.actionCreators, dispatch),
    setFeatures: bindActionCreators(setFeatures, dispatch)
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
