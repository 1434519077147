import { createReducer } from 'lib/createReducer';

// Actions
export const SET_DRIVERS = 'SET_DRIVERS';
export const SET_DRIVER_STATE = 'SET_DRIVER_STATE';
export const LIST_DRIVERS_REQUEST = 'LIST_DRIVERS_REQUEST';

// Reducer
const initialState = {
    drivers: []
};

export default createReducer(initialState, {
    [LIST_DRIVERS_REQUEST]: (state, data) => ({
        ...state,
        loading: true,
        drivers: []
    }),
    [SET_DRIVERS]: (state, drivers) => ({
        ...state,
        drivers: drivers,
        loading: true
    })
});

// Action Creators
export const setDrivers = drivers => dispatch =>
    dispatch({
        type: SET_DRIVERS,
        data: drivers
    });
